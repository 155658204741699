.clickable {
    cursor: pointer
}

.hymnToolbarText {
    font-family: OpenSans;
    font-size: 17px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.41;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.hymnButton {
    border: 1.5px solid #b76881;
    border-radius: 5px;
    padding: 5px;
    /* vertical-align: middle; */
    align-items: center;
    color: #b76881;
    text-align: center;
    cursor: pointer;
}

.hymnButton:hover {
    background-color: #DFDFDF;
}

.hymnNumber {
    font-family: 'Literata';
    font-size: var(--hymn-number-font-size);
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: 1px;
    color: var(--text-90);
    padding-bottom: 0.5em;
}

.tuneContainer {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-bottom: 40px;
    background-color: var(--footer-fill);
    bottom: 0;
    border-top: 0.5px var(--seperator-1) solid;
    padding-left: 5%;
    padding-right: 5%;
}

.tuneHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: Literata;
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: normal;
    color: var(--text-70);
    padding-top: 16px;
    padding-bottom: 16px;
}

.relatedHymnHeader {
    font-family: OpenSans;
    font-size: 17px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.41;
    letter-spacing: 0.1px;
    color: var(--text-90);
    text-align: left;
}

.relatedHymnChildHeader {
    font-family: OpenSans;
    font-size: 13px;
    font-weight: 200;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.54;
    letter-spacing: normal;
    color: #4a4b5b;
}

.relatedHymnRow {
    background: white;
    border-bottom: 1px var(--seperator-1) solid;
}

.relatedHymnNumberBox {
    width: 42px;
    height: 34px;
    margin: 5px 12px 13px 0;
    padding: 0 0 4px;
    border-radius: 1px;
    border: solid 1px var(--hymn-border);
    background-color: var(--hymn-fill);
}

.relatedHymnNumber {
    width: 42px;
    height: 30px;
    font-family: Literata;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.5px;
    text-align: center;
    color: var(--text-90);
}

.relatedHymnText {
    text-overflow: ellipsis;
    font-family: OpenSans;
    font-size: 14px;
    font-weight: 200;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.54;
    letter-spacing: normal;
    color: var(--text-90);

    /* Needed to make ellipsis work */
    overflow: hidden;
    white-space: nowrap;
}

.relatedHymnChevron {
    color: var(--seperator-1);
    font-size: 13px;
}

.tuneBody {
    padding-bottom: 16px
}

.relatedHymnBody {
    padding-top: 16px;
}

.tuneChevron {
    color: var(--disclosure-2);
    font-size: 13px;
    padding-top: 10px;
}

.tuneName {
    font-family: Literata;
    font-size: 19px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.37;
    letter-spacing: normal;
    color: var(--text-90);
    text-align: left;
}

.tuneMetre {
    font-family: Literata;
    font-size: 19px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.37;
    letter-spacing: normal;
    color: var(--text-90);
    text-align: right;
}

.musicContent {
    padding-top: 8px;
    display: flex;
    justify-content: space-between;
}

/* For Music's IonRow/IonCol */
.hydrated {
    visibility: inherit;
    padding: 0px;
}

.tuneMusic {
    font-family: Literata;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: italic;
    line-height: 1.44;
    letter-spacing: -0.11px;
    color: var(--text-90);
    padding-right: 6px
}

.tuneItalic {
    font-family: Literata;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: italic;
    line-height: 1.44;
    letter-spacing: -0.11px;
    color: var(--text-90);
}

.personRestOfName {
    font-family: Literata;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: -0.11px;
    color: var(--text-90);
}

.tuneComposer {
    font-family: Literata;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: -0.11px;
    color: var(--text-90);
}

.tuneSource {
    font-family: Literata;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: -0.11px;
    color: var(--text-90);
    display: inline;
}

.tuneHarmoniser {
    font-family: Literata;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: -0.11px;
    color: var(--text-90);
    display: inline;
}

.tuneArranger {
    font-family: Literata;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: -0.11px;
    color: var(--text-90);
    display: inline;
}

.tuneAdapter {
    font-family: Literata;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: -0.11px;
    color: var(--text-90);
    display: inline;
}

.tuneEditor {
    font-family: Literata;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: -0.11px;
    color: var(--text-90);
    display: inline;
}

.tuneDate {
    font-family: Literata;
    font-size: 18px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: -0.11px;
    color: var(--text-90);
}

.tuneSuffix {
    font-family: Literata;
    font-size: 18px;
    font-weight: 400;
    font-stretch: normal;
    font-style: italic;
    line-height: 1.44;
    letter-spacing: -0.11px;
    color: var(--text-90);
}

.seeAlsoContainer {
    font-family: Literata;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: -0.11px;
    color: var(--text-90);
    padding-bottom: 2em;
}

.seeAlsoFirstLine {
    text-align: left;
}

.seeAlsoHymnNo {
    float: right;
}

p {
    font-family: Literata;
    color: black;
    margin: 0;
    line-height: 1.25;
    /* line-height: 1.33; original padding based on Zeplin */
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
}

p.verseNumber {
    display: inline;
    font-family: Literata;
    font-size: var(--verse-number-font-size);
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    /* line-height: 1.85; original padding based on Zeplin */
    letter-spacing: normal;
    color: #000000;
    padding-right: 0.8em;
}

a {
    color: black !important;
}

p.verse {
    font-size: var(--verse-font-size);
    display: inline;
}

p.linespace {
    min-height: 1em;
}

.verse1 {
    font-size: var(--verse-font-size);
    padding-left: 1.7em;
}

.verse2 {
    font-size: var(--verse-font-size);
    padding-left: 2.3em;
    margin-bottom: 0px !important;
}

.verse3 {
    font-size: var(--verse-font-size);
    padding-left: 2.7em;
}

.poems {
    font-family: Literata;
    font-size: var(--poems-font-size);
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: -0.11px;
    color: var(--text-90);
    text-align: right;
    padding-right: 0.2em;
}

.fsc {
    font-size: var(--fsc-font-size);
    font-weight: 600;
}

.fn {
    width: 100%;
    font-family: EBGaramond;
    font-size: var(--fn-font-size);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;
    text-align: center;
    color: var(--text-90);

}

.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.bottomToolBar {
    position: absolute;
    bottom: 0px;
    left: 0px;
    height: 50px;
    background-color: white;
    width: 100%;
    z-index: 500;
    margin-bottom: env(safe-area-inset-bottom);
    border-top: solid 0.5px var(--footer-border)
}

.bottomToolBarContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.prevNextHymnToolBarContainer{
    text-align: center;
    min-width: 55px;
    display: flex;
    align-items: center;
}

.prevNextHymnToolBarText{
    font-family: Literata;
    font-size: 19px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.37;
    letter-spacing: normal;
    color: var(--cta-1-50);
}

.feedBackOnClick:active{
    opacity: 0.5;
    color: #05176d !important
}

.currentPageToolBarIcon{
    font-family: Literata;
    font-size: 19px;
    color: var(--cta-1-50);
}

.currentPageToolBarText{
    font-family: OpenSans;
    font-size: 17px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.41;
    letter-spacing: normal;
    text-align: center;
    color: var(--text-70);
}

.swiper-slide {height: 1px !important}
.swiper-slide-next{height:1px !important} /* Feel free to specify a height value */    
.swiper-slide-prev{height:1px !important} /* Feel free to specify a height value */    
.swiper-slide-active { height:auto !important}

.fontBoxPointer {

    position: fixed;
    width: 0; 
    height: 0; 
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    /* right: 90px; */
    border-bottom: 15px solid var(--footer-fill);
    z-index: 1000;

}

.fontBoxContainer {
    position: fixed;
    width: 17em;
    /* height: 103px; */
    background: white;
    /* right: 30px; */
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    margin-top: 15px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    z-index: 500;
}

.fontBoxContent {
    display: flex;
    justify-content: space-around;
}

.fontBoxSeparator {
    height: 10px;
    background: var(--footer-fill);
    border-top: 1px solid var(--seperator-1);
    border-bottom: 1px solid var(--seperator-1);
}

.toolBarIcon:active{
    opacity: 0.5
}

.fontBoxText {
    font-family: Literata;
    font-size: 19px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.37;
    letter-spacing: normal;
    color: var(--text-90);
    padding-left: 10px;
    padding-top: 7px;
}

.fontResizeBtn:active {
    background: var(--footer-fill)
}

.bookmarkAndAddToListMenu {
    display: flex; 
    flex-direction: column; 
    justify-content: space-evenly;
    height: 100%;
    font-family: OpenSans;
    font-size: 17px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.41;
    letter-spacing: 0.1px;
    color: var(--text-90);
}

.bookmarkAndAddToListItem {
    display: flex;
    flex-direction: row; 
    justify-content: space-between;
    align-items: center; 
    padding-left: 12px;
}

.bookmarkAndAddToListItem:active {
    background: var(--footer-fill)
}

.selectable-text {
    -webkit-user-select: text !important;
    -moz-user-select: text !important;
    -ms-user-select: text !important;
    user-select: text !important;
}

.copy-to-clipboard {
    font-family: OpenSans;
    font-size: 17px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.41;
    letter-spacing: 0.1px;
    color: var(--text-90);
    padding: 12px;
}

.copy-to-clipboard:active {
    background: var(--footer-fill);
}