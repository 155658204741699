.subscriptionsContainer {
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 16px;
    padding-bottom: 12px;
}

.subscriptionsTitleContainer {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding-bottom: 16px;
}

.subscriptionsTitle {
    font-family: Literata;
    font-size: 19px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.37;
    letter-spacing: normal;
    color: var(--text-90);
    padding-right: 10px;
    max-width: 94%;
}

.subscriptionsChevron {
    font-size: 19px;
    color: var(--disclosure-2);
}

.subscriptionsSubTitle {
    font-family: OpenSans;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 0.1px;
    color: var(--text-90);
    padding-top: 16px;
}

.subscriptionsSubTitle2 {
    font-family: OpenSans;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.53;
    letter-spacing: normal;
    color: var(--text-90);
}

.subscriptionsSubTitle3 {
    font-family: OpenSans;
    font-size: 17px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.41;
    letter-spacing: normal;
    text-align: center;
    color: var(--cta-1-50);
}

.subscriptionsSubTitle4 {
    font-family: OpenSans;
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: var(--text-70);
}

.subscriptionsSubTitle5{
    font-family: OpenSans;
    font-size: 17px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.53;
    letter-spacing: normal;
    color: var(--text-90);
}

.loginContainer {
    padding-top: 16px;
}

.loginTitle {
    font-family: OpenSans;
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: var(--text-70);
    padding-bottom: 4px;
}

.loginInput {
    height: 44px;
    border-radius: 6px;
    border: solid 1px var(--input-border);
    background-color: var(--input-fill);
    font-family: OpenSans;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.53;
    letter-spacing: normal;
}

.signInContainer {
    display: flex;
    justify-content: flex-end;
    padding-top: 16px;
}

.subscriptionButtonSpinner {
    --color: white;
    width: 22px;
    height: 22px;
    margin-left: 10px;
  }

.subscriptionBlueBtn {
    --border-radius: 4px;
    --border-color: var(--cta-1-50);
    --color: white;
    --border-width: 1px;
    text-transform: none;
    font-family: OpenSans !important;
    font-size: 17px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.41;
    letter-spacing: normal;
    text-align: center;
}

.subscriptionBtnText {
    font-family: OpenSans;
    font-size: 17px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.41;
    letter-spacing: normal;
    text-align: center;
    color: var(--card-fill);
}


.subscriptionPriceBtn {
    width: inherit;
    height: 50px;
    padding: 12px 0 14px;
    border-radius: 4px;
    background-color: var(--cta-1-50);
}

.subscriptionClearBtn {
    --border-radius: 4px;
    --border-color: var(--cta-1-50);
    --color: var(--cta-1-50);
    --border-width: 1px;
    height: 50px;
    text-transform: none;
    font-family: OpenSans !important;
    font-size: 17px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.41;
    letter-spacing: normal;
    text-align: center;
}

.subscriptionRestoreBtn {
    --border-radius: 4px;
    --border-width: 1px;
    box-shadow: none;
    height: 50px;
    text-transform: none;
    font-family: OpenSans !important;
    font-size: 17px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.41;
    letter-spacing: normal;
    text-align: center;
}

.btn-no-border {
    --border-width: 0px !important;
}

.subscriptionClearBtnText {
    font-family: OpenSans;
    font-size: 17px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.41;
    letter-spacing: normal;
    text-align: center;
    --color: var(--cta-1-50);
    /* color: var(--cta-1-50); */
}

.subscriptionInfoText {
    font-family: OpenSans;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 0.1px;
    color: var(--text-90);
}

.subscriptionInfoTextHighlight {
    font-family: OpenSans;
    font-size: 15px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 0.1px;
    font-weight: 600;
    color: var(--cta-1-50) !important;
}