.create-new-list-container {
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 16px;
    padding-bottom: 12px;
}

.differentContainer {
    min-height: 100% !important;
}

.create-new-list-header-container {
    display: flex; 
    justify-content: space-between; 
    align-items: center; 
    padding-bottom: 15px
}

.create-new-list-header {
    font-family: Literata;
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: normal;
    color: var(--text-90);
}

.create-new-list-sub-header {
    font-family: Literata;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: normal;
    color: var(--text-90);
    padding-bottom: 15px;
}

/* Just set width on the JSX div using style prop */
.create-new-list-seperator {
    height: 0.5px;
    background-color: var(--seperator-2);
}

.create-new-list-input-container {
    padding-top: 15px;
    font-family: OpenSans;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.53;
    letter-spacing: normal;
    color: #80808c;
}

.create-new-list-input {
    height: 46px;
    border-radius: 6px;
    border: solid 1px var(--input-border);
    background-color: var(--input-fill);
    font-family: OpenSans;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.53;
    letter-spacing: normal;
    display: flex;
    align-items: center;
    padding-left: 12px;
}

.create-new-list-add-hymn-btn {
    height: 50px;
    margin-top: 15px;
    padding-left: 25px;
    border-radius: 8px;
    border: solid 0.5px var(--cta-2-border);
    background-color: var(--cta-2-fill);
    display: flex;
    align-content: center;
}

.create-new-list-add-hymn-btnTxt {
    padding-top: 14px;
    font-family: OpenSans;
    font-size: 17px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.41;
    letter-spacing: normal;
    color: var(--cta-2-text);
}

.create-new-list-save-btn {
    height: 50px;
    margin-top: 15px;
    border-radius: 8px;
    border: solid 0.5px var(--cta-2-border);
    background-color: var(--cta-2-text);
    display: flex;
    align-content: center;
}

.create-new-list-save-btnTxt {
    font-family: OpenSans;
    font-size: 17px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.41;
    letter-spacing: normal;
    color: var(--cta-2-fill);
    margin: auto;
}

.add-hymns-modal-custom-css {
    background-color: black;
    height: 100%;
}