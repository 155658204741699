.modal-wrapper.sc-ion-modal-md, .modal-shadow.sc-ion-modal-md {
    min-height: 100%;
    min-width: 100%;
}

.modal-custom-css {
    background-color: var(--content-background);
    height: 100%;
}

.dashboard-container {
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 16px;
}

.menuListText {
    font-family: OpenSans;
    font-size: 17px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.41;
    letter-spacing: 0.1px;
    color: var(--text-90);
}

.noHymnsOnSunday {
    font-family: OpenSans;
    font-size: 16px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.41;
    letter-spacing: 0.1px;
    color: var(--text-90);
    text-align: center;
    padding: 16px;

}

.searchBar {
    height: 44px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 8px;
    border: solid 1px var(--input-border);
    background-color: #ffffff;
    font-family: OpenSans;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    letter-spacing: -0.09px;
    color: var(--text-90);
    display: flex;
    align-items: center;    
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.searchBarTxt {
    font-family: OpenSans;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    letter-spacing: -0.09px;
    color: var(--text-90);
}

.searchBarContent {
    min-width: 355px;
    max-width: 825px;
    margin: auto;
}

.sundayHymnTitle {
    font-family: Literata;
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: normal;
    color: var(--text-90);
    padding-bottom: 8px;
}

.sundayHymnDate {
    font-family: OpenSans;
    font-size: 17px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.41;
    letter-spacing: 0.1px;
    color: var(--text-70);
    padding-bottom: 16px;
}

.myListsText {
    display: flex; 
    justify-content: space-between;
    align-items: center;
    padding-bottom: 16px;
}

.myListsHeader {
    font-family: Literata;
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: normal;
    color: var(--text-90);
}

.smallBtn {
    --border-radius: 16px;
    --color: var(--cta-1-40) !important;
    --box-shadow:none;
    height: 32px;
    text-transform: none;
}

.smallBtnText {
    font-family: OpenSans;
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: var(--cta-1-40);
}

.createListBtn {
    --border-radius: 8px;
    height: 50px;
    margin-top: 15px;
    margin-bottom: 15px;
    --border-style: solid 0.5px var(--cta-2-border);
    --box-shadow:none;
    text-transform: none;
}

.createListBtnTxt {
    font-family: OpenSans;
    font-size: 17px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.41;
    letter-spacing: normal;
    color: var(--cta-2-text);
}

.listsContent {
    display: flex;
    padding-top: 12px;
    padding-left: 12px;
    padding-right: 12px;
    align-items: center;
}

.listHeader {
    font-family: OpenSans;
    font-size: 19px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.37;
    width: 65%;
    letter-spacing: normal;
    color: var(--text-90);
    width: 100%;
}

.listChevron {
    font-size: 13px;
    color: var(--disclosure-2);
    padding-top: 5px;
}

.listDate {
    font-family: OpenSans;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 0.1px;
    color: var(--text-90);
    padding-left: 12px;
}

.onBoardingModal {
    --height: 86%;
    --width: 88%;
    --background-color: var(--card-fill);
    --border-radius: 4px;
    --border-style: solid;
    --border-width: 1px;
    --border-color: var(--modal-border);
}

.trialExpiredModal {
    --height: 70%;
    --width: 88%;
    --background-color: var(--card-fill);
    --border-radius: 4px;
    --border-style: solid;
    --border-width: 1px;
    --border-color: var(--modal-border);
}

.onBoardingHeader {
    font-family: Literata;
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    color: var(--text-90);
    padding-bottom: 16px;
}

.onBoardingText {
    font-family: OpenSans;
    font-size: 17px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.53;
    letter-spacing: normal;
    color: var(--text-90);
    padding-bottom: 16px;
}

.onBoardingBtnSeperatorText{
    font-family: OpenSans;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: -0.1px;
    text-align: center;
    color: #a1a2ab;
    padding-left: 7px;
    padding-right: 7px;
}

.aboutTheAppHeader {
    font-family: Literata;
    font-size: 19px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.37;
    letter-spacing: normal;
    color: var(--text-90);
    padding-bottom: 4px;
}

.howToUseSubHeader{
    font-family: Literata;
    font-size: 17px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.41;
    letter-spacing: normal;
    color: var(--text-90);
}

.aboutAppText {
    font-family: OpenSans;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.53;
    letter-spacing: normal;
    color: var(--text-90);
    padding-bottom: 16px;
}

.canterburyPressLink {
    font-weight: 600 !important;
    color: var(--cta-1-50) !important;
}

.tosAndPrivacyLink {
    color: var(--cta-1-50) !important;
}

.aboutAppCopyrightText {
    font-family: OpenSans;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.53;
    letter-spacing: normal;
    color: var(--text-90);
}