.custom-list-item{

    border-left: 1px solid var(--card-border);
    border-right: 1px solid var(--card-border);
    background: white;
    display: flex;
    /* justify-content: space-between; */
    align-items: center;

}

.custom-list-item:active {
  background: #dcdcdc;
}

.custom-list-item:hover {
  background: #f6f6f6;
}

.listSubtitle{
    font-family: OpenSans !important;
    font-size: 13px !important;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.54;
    letter-spacing: normal;
    color: #4c3b3b !important;
  }
  
  .number-box-small {
    width: 28px;
    height: 22px;
    margin: 5px 5px 5px 0;
    display: inline-block;
    padding: 0 0 4px;
    border-radius: 1px;
    border: solid 1px var(--hymn-border);
    background-color: var(--hymn-fill);
  }
  
  .number-box-small:active{
  background-color: #b4aea8;
  }
  
  .number-small {
    font-family: Literata;
    font-size: 13px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.54;
    letter-spacing: 0.5px;
    text-align: center;
    color: var(--text-90);
  }
  
  /* .number-box {
    width: 42px;
    height: 34px;
    margin: 5px 12px 13px 0;
    padding: 0 0 4px;
    border-radius: 1px;
    border: solid 1px var(--hymn-border);
    background-color: var(--hymn-fill);
  }
  
  .number {
    width: 42px;
    height: 30px;
    font-family: Literata;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.5px;
    text-align: center;
    color: var(--text-90);
  } */