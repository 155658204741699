
/* The two elements below should prevent iontabs from exceeding the iOS menu button */
/* body {
  margin-top: env(safe-area-inset-top);
  margin-top: constant(safe-area-inset-top);
}

ion-tab-bar {
  margin-bottom: env(safe-area-inset-bottom);
}

ion-content {
    margin-bottom: env(safe-area-inset-bottom);
} */

/* Ensures modals are fullscreen on wider screens */
ion-modal {
  --width: 100%;
  --height: 100%;
  --border-radius: 0;
}

ion-item {
    --min-height: 56px;
    --padding-bottom: 0px;
    --padding-end: 10px;
    --padding-start: 10px;
    --padding-top: 0px;
    --color: var(--text-90);
    --background: white;
    --border-color: var(--seperator-1);
    font-family: OpenSans;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    color: var(--text-90);
}

ion-item:last-child {
    --inner-border-width: 0;
}

ion-note {
  margin-right: 12px;
}

ion-toolbar {
    --background: var(--hymns-red); 
    --color: white;
    text-align: center;
    font-family: OpenSans;
    font-size: 17px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.41;
    letter-spacing: normal;
}

ion-back-button {
    color: #ffffff;
    font-family: OpenSans;
    font-size: 17px;
    text-transform: none;
    /* font-weight: 600; */
    font-stretch: normal;
    font-style: normal;
    line-height: 1.41;
    letter-spacing: normal;
}

ion-tab-bar{
    --background: white;
    height: 50px
}

ion-tab-button {
    display: flex;
    /* position: relative; */
    /* flex-flow: inherit; */
    /* align-items: inherit; */
    width: 100%;
    height: 100%;
    z-index: 1;
    justify-content: unset !important;
}

ion-button {
    --color: white;
}

ion-header button, .back-button {
    position: absolute !important;
    z-index: 5;
}

ion-title {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0 90px 1px;
    width: 100%;
    height: 100%;
    text-align: center;
}

ion-backdrop {
  opacity: 0.33 !important;
}

ion-select {
  width: 100%;
}

.backButtonTxt {
  font-family: OpenSans;
  font-size: 17px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.41;
  letter-spacing: 0.1px;
  color: #fff;
  text-transform: none !important;
}

/* This is just the padding for the placeholder/text in ion-input */
.native-input.sc-ion-input-ios {
  -webkit-padding-start: 10px;
  padding-inline-start: 10px;
  -webkit-padding-end: 10px;
  padding-inline-end: 10px;
}

.sc-ion-input-ios {
  color: var(--text-90) !important;
}

.native-input.sc-ion-input{
  color: var(--text-90) !important;
}
.native-input.sc-ion-input-md{
  color: var(--text-90) !important;
}

.datetime-text {
  padding: 10px
}

.flexCenter {
    display: flex;
    align-items: center;
    justify-content: center;
}

.allContentContainer {
    min-height: 100%;
    display: flex;
    flex-direction: column;
}

.contentContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 0.75em;
  padding-left: 5%;
  padding-right: 5%;
}

.flexChildFullHeight {
  flex: 1 1;
  height: 100%;
}

/* Changes size and position of custom search icon on ios */
.searchbar-search-icon.sc-ion-searchbar-ios {
    left: -5px;
    width: 44px;
}

.searchbar-search-icon.sc-ion-searchbar-md, .searchbar-cancel-button.sc-ion-searchbar-md{
    top: 0;
    left: 5px;
    width: 44px;
    height: 44px;
}

.searchbar-input-container.sc-ion-searchbar-md{
    font-family: OpenSans;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.53;
    letter-spacing: normal;
    color: #655a5a;  
    background-color: #e0dad3;
    border: solid 1px #c7bba3;
}

/* Sets the search bar placeholder on ios */
.searchbar-left-aligned.sc-ion-searchbar-ios-h .searchbar-input.sc-ion-searchbar-ios {
    font-family: OpenSans;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.53;
    letter-spacing: normal;
    color: #655a5a;  
    background-color: #e0dad3;
    border: solid 1px #c7bba3;
}

.sc-ion-searchbar-ios-h {
    height: 37px !important;
}

/* Sets the input placeholder */
.native-input.sc-ion-input-ios {
    font-family: OpenSans;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.53;
    letter-spacing: normal;
    color: #80808c; /* Changes the font color in an iOS input box */ 
}

.content-border {
    border-radius: 4px;
    border: solid 1px var(--card-border);
}

.listContainer {
  padding-left: 12px;
  padding-right: 12px;
}

.listItemText {
  font-family: OpenSans;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: var(--text-90);
  overflow: hidden; 
  text-overflow: ellipsis; 
  display: -webkit-box; 
  -webkit-line-clamp: 2; 
  -webkit-box-orient: vertical;
}

.listItemTextBold {
  font-family: OpenSans;
  font-size: 17px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.41;
  letter-spacing: 0.1px;
  color: var(--text-90);
}

.seperator {
  height: 1px;
  background-color: var(--seperator-2);
}

ion-toggle {
  /* --background: #000; */
  --background-checked: var(--seperator-1);
  height: 30px
}

/* Changes the right chevron color on the ion-list items */
.item::part(detail-icon){
  color: var(--seperator-1);
  opacity: 100%;
}

/* Changes the padding of the right icon on ion-list items */
.item::part(native){
  --inner-padding-end: 0px;
}

/* Small phone e.g. iPhone SE */
@media screen and (max-height: 670px) {

  .content {
    float: left;
    min-width: 100%;
    max-width: 100%;
    background-color: var(--card-fill);
  }

  .tuneContent {
    float: left;
    min-width: 100%;
    max-width: 100%;
    background-color: var(--footer-fill);
  }

  /* Hide the book cover on mobile screens */
  .onBoardingBookCover {
    display: none;
  }

  .web-graphic{
    display: none
  }

}

/* Normal phone screen widths */
@media screen and (max-width: 600px) and (min-height: 671px) {

  .content {
    float: left;
    min-width: 100%;
    max-width: 100%;
    background-color: var(--card-fill);
  }

  .tuneContent {
    float: left;
    min-width: 100%;
    max-width: 100%;
    background-color: var(--footer-fill);
  }

  .hymnContent {
    width: 350px;
  }

  /* Show the book cover on mobile screens */
  .onBoardingBookCover {
    display: flex;
    justify-content: center;
    padding-bottom: 16px;
  }

  /* On the web version, on the subscriptions page, if the screen isn't wide enough, use these css classes to just
   * show the normal header without the 'Get from iOS/Play' store image   
   */
  .web-graphic{
    display: none
  }

  .web-show-header {
    display: block
  }

}

/* Normal tablet screen widths */
@media screen and (min-width: 601px) and (max-width: 900px) {

  .content {
    float: left;
    min-width: 80%;
    max-width: 80%;
    background-color: var(--card-fill);
    /* background-color: rgb(185, 185, 255); */
  }

  .hymnContent {
    width: 800px;
  }

  .tuneContent {
    float: left;
    min-width: 80%;
    max-width: 80%;
    background-color: var(--footer-fill);
  }

  /* Only center the header on wider screens */
  .onBoardingHeader {
    text-align: center;  
  }

  /* Show the book cover on mobile screens */
  .onBoardingBookCover {
    display: flex;
    justify-content: center;
    padding-bottom: 16px;
  }

  /* On the web version, on the subscriptions page, if the screen isn't wide enough, use these css classes to just
   * show the normal header without the 'Get from iOS/Play' store image   
   */
  .web-graphic{
    display: none
  }

  .web-show-header {
    display: block
  }

}

/* Wider tablet or normal monitor widths */
@media screen and (min-width: 901px) and (max-width: 1400px) {

  .content {
    float: left;
    min-width: 80%;
    max-width: 80%;
    background-color: var(--card-fill);
    /* background-color: rgb(255, 210, 255); */
  }

  .hymnContent {
    width: 450px;
  }

  .tuneContent {
    float: left;
    min-width: 80%;
    max-width: 80%;
    background-color: var(--footer-fill);
  }

  /* Only center the header on wider screens */
  .onBoardingHeader {
    text-align: center;  
  }

  /* Show the book cover on mobile screens */
  .onBoardingBookCover {
    display: flex;
    justify-content: center;
    padding-bottom: 16px;
  }

  /* On the web version, on the subscriptions page, if the screen isn't wide enough, use these css classes to just
   * show the normal header without the 'Get from iOS/Play' store image   
   */
  .web-graphic{
    display: none
  }

  .web-show-header {
    display: block
  }

}

/* Wider monitor widths */
@media screen and (min-width: 1401px) {

  .content {
    float: left;
    min-width: 60%;
    max-width: 60%;
    background-color: var(--card-fill);
    /* background-color:rgb(159, 255, 159); */
  }

  .hymnContent {
    width: 500px;
  }

  .tuneContent {
    float: left;
    min-width: 60%;
    max-width: 60%;
    background-color: var(--footer-fill);
  }

  /* Only center the header on wider screens */
  .onBoardingHeader {
    text-align: center;  
  }

  /* Show the book cover on mobile screens */
  .onBoardingBookCover {
    display: flex;
    justify-content: center;
    padding-bottom: 16px;
  }

  /* On the web version, on the subscriptions page, if the screen IS wide enough, use these css classes to just
   * show 'Get from iOS/Play' store image without the red heading (as this isn't necessary when showing the image)
   */
  .web-graphic{
    display: block
  }

  .web-show-header {
    display: none
  }

}

.tabTextActive {
    font-family: OpenSans;
    font-size: 11px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.27;
    letter-spacing: normal;
    text-align: center;
    color: var(--text-90);
}

.tabTextInactive {
    font-family: OpenSans;
    font-size: 11px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.27;
    letter-spacing: normal;
    text-align: center;
    color: #a6a19e;
}

/* @font-face {
    font-family: 'OpenSans';
    src: local('OpenSans'), url(/src/theme/fonts/Open_Sans/OpenSans-SemiBold.ttf) format('truetype');
    font-display: block;
} */

@font-face {
    font-family: 'OpenSans';
    src: url('/src/theme/fonts/Open_Sans/OpenSans-Regular.ttf');
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    font-display: block;
}

@font-face {
    font-family: 'OpenSans';
    src: url('/src/theme/fonts/Open_Sans/OpenSans-SemiBold.ttf');
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    font-display: block;
}

@font-face {
    font-family: 'OpenSans';
    src: url('/src/theme/fonts/Open_Sans/OpenSans-Bold.ttf');
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    font-display: block;
}
  
  @font-face {
    font-family: 'Literata';
    src: url('/src/theme/fonts/Literata/static/Literata/Literata-ExtraLight.ttf');
    font-weight: 200;
    font-display: block;
  }
  
  @font-face {
    font-family: 'Literata';
    src: url('/src/theme/fonts/Literata/static/Literata/Literata-Light.ttf');
    font-weight: 300;
    font-display: block;
  }
  
  @font-face {
    font-family: 'Literata';
    src: url('/src/theme/fonts/Literata/static/Literata/Literata-Regular.ttf');
    font-weight: 400;
    font-display: block;
  }
  
  @font-face {
    font-family: 'Literata';
    src: url('/src/theme/fonts/Literata/static/Literata/Literata-Medium.ttf');
    font-weight: 500;
    font-display: block;
  }
  
  @font-face {
    font-family: 'Literata';
    src: url('/src/theme/fonts/Literata/static/Literata/Literata-SemiBold.ttf');
    font-weight: 600;
    font-display: block;
  }
  
  @font-face {
    font-family: 'Literata';
    src: url('/src/theme/fonts/Literata/static/Literata/Literata-Bold.ttf');
    font-weight: 700;
    font-display: block;
  }
  
  @font-face {
    font-family: 'Literata';
    src: url('/src/theme/fonts/Literata/static/Literata/Literata-ExtraBold.ttf');
    font-weight: 800;
    font-display: block;
  }
  
  @font-face {
    font-family: 'Literata';
    src: url('/src/theme/fonts/Literata/static/Literata/Literata-Black.ttf');
    font-weight: 900;
    font-display: block;
  }

  /* Garamond */

  @font-face {
    font-family: 'EBGaramond';
    src: url('/src/theme/fonts/EB_Garamond/static/EBGaramond-Italic.ttf');
    font-weight: normal;
    font-style: italic;
    font-stretch: normal;
    font-display: block;
  }
  @font-face {
    font-family: 'EBGaramond';
    src: url('/src/theme/fonts/EB_Garamond/static/EBGaramond-Regular.ttf');
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    font-display: block;
  }
  @font-face {
    font-family: 'EBGaramond';
    src: url('/src/theme/fonts/EB_Garamond/static/EBGaramond-SemiBold.ttf');
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    font-display: block;
  }