.browseListTitle {
    font-family: OpenSans;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: 0.2px;
    color: var(--text-90);
    padding-top: 6px;
    padding-bottom: 6px;
}

.numberOfHymns {
    font-family: OpenSans;
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: var(--text-70);
    padding-top: 6px;
    padding-bottom: 16px;
}

.number-box {
    width: 42px;
    height: 34px;
    /* margin: 5px 12px 13px 0; */
    padding: 0 0 4px;
    border-radius: 1px;
    border: solid 1px var(--hymn-border);
    background-color: var(--hymn-fill);
}

.number-box-minimized {
  width: 42px;
  height: 34px;
  margin: 5px 12px 13px 0;
  padding: 0 0 4px;
  border-radius: 1px;
  border: solid 1px var(--hymn-border);
  background-color: var(--hymn-fill);
}

.number-box-minimized:active{
  background-color: #b4aea8;
}

.number {
    width: 42px;
    height: 30px;
    font-family: Literata;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.5px;
    text-align: center;
    color: var(--text-90);
}

.hymnTotal {
  font-family: OpenSans;
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: var(--text-70);
  padding-bottom: 10px;
  padding-top: 10px;
  border-bottom: 0.5px var(--seperator-1) solid;
  background-color: var(--content-background);
}

.alphabeticalList {
  max-width: 16px;
  padding: 20px;
  color: #0a7aff;
  /* font-size: 11px; */
}

.bibleBookFilterDropdownText{
  font-family: OpenSans;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.09px;
  line-height: 26px;
  color: var(--text-90);
}

.bibleBookFilterDropdownContainer{
  display: flex;
  justify-content: flex-end;
  padding-right: 8px;
  align-items: center;
  background: white;
  border-radius: 8px;
  border: 1px solid var(--border);
}

.bibleBookFilterTitle {
  font-family: OpenSans;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  color: #4B4D5E;
  padding-bottom: 8px;
}

.bibleBookFilterModalItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}